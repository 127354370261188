import React from "react";
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import SEO from "../components/SEO";

const AboutContentStyles = styled.div`
  
  p {
    margin-bottom: 2.5rem;
  }

`;

const AboutImageStyles = styled.div`

  display: grid;
  --cols: 2;
  grid-template-columns: repeat(var(--cols), 1fr);
  grid-gap: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  border-radius: 3px;

  img {
    display: block;
    max-width: 100%;
    border-radius: 3px;
  }

`;

export default function About({ location, data }) {
  const portfolioImages = data.siteSettings.portfolioImages;
  return (
    <>
        {/* eslint-disable-next-line */}
        <SEO location={location} title="About" description="I work as a professional front end developer, punching the clock full time. I also build content driven websites for clients as a freelancer when the opportunity arises. I’m partial to Javascript and CSS, and am currently gravitating in the sphere of the JAMstack, developing sites with a serverless, or ‘headless’, backend architecture." />
        <h2 className="page-heading">about</h2>
        <AboutContentStyles>
          <p>I work as a professional <span className="highlight">front end developer</span>, punching the clock full time. I also build content driven websites for clients as a freelancer when the opportunity arises. I’m partial to Javascript and CSS, and am currently gravitating in the sphere of the JAMstack, developing sites with a serverless, or ‘headless’, backend architecture.</p>
          <p>I spend some of my free time teaching others to code, tutoring learners in all aspects of Javascript, HTML, and CSS.</p>
          <p>In my work, I’m committed to a UX that includes all communities and all users, and in which I strive to make accessible and usable UI components and web pages.</p>
          <p>Like the enthusiasm with which I approach writing code, I have a profound fervor for words on a page (be it web or paper). I spend a lot of time immersed in books, and I fancy that one day I will share my analog scribblings with the world, as I’ve done with my code. When I’m not at my laptop, I like to cook a fine meal at home, get lost in a game of dungeons &amp; dragons, or play the piano for my dog, Fran.</p>
        </AboutContentStyles>
        <AboutImageStyles>
          {portfolioImages.map((image) => (
            <GatsbyImage
              image={getImage(image.portfolioImage.asset)}
              key={image.portfolioImage.asset._id}
              alt={image.title} />
          ))}
        </AboutImageStyles>
      </>
  ) 
}

export const query = graphql`
  query {
    siteSettings: sanitySiteSettings {
      portfolioImages {
        portfolioImage {
          asset {
            gatsbyImageData (
              placeholder: BLURRED
              height: 425
            )
            _id
          }
        }
        title
      }
    }
  }
`;